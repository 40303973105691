import CommonHeader from '@/components/common/CommonHeader';
import NoData from '@/components/common/NoData';
export default {
  name: 'PayslipNoData',
  components: {
    CommonHeader,
    NoData
  },
  data() {
    return {
      noDataDesc: '暂无工资发放记录'
    };
  }
};